import {node} from 'prop-types'
import React from 'react'
import Section from 'components/UI/Section'

import useStyles from './styles'

const FlexDiv = ({children}) => {
  const classes = useStyles()

  return (
    <Section hasVerySmallPadding>
      <div className={classes.flex}>{children}</div>
    </Section>
  )
}

FlexDiv.propTypes = {
  children: node,
}

FlexDiv.defaultProps = {
  children: null,
}

export default FlexDiv
